<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    console.log(`환경 : ${this.$CONF.NODE_ENV}`);
  },
};
</script>
